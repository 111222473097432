<template>
  <v-footer
    class="b-footer pt-5"
    color="gray_color"
  >
    <v-container>
      <v-layout class="row">
        <v-flex class="xs12">
          <v-layout
            justify-end
            row
            wrap
          >
            <div class="d-flex align-right white--text">
              <i18n
                path="copyright"
                tag="span"
              >
                <span place="copyright">
                  <v-icon
                    color="white"
                    class="b-icon"
                  >
                    fa fa-copyright
                  </v-icon>
                </span>
              </i18n>
            </div>
            <div class="mx-1 d-flex">
              <figure class="d-flex align-center">
                <img
                  class="ml-1 b-footer__designed"
                  src="../../../public/img/logo-cadena-apps.png"
                >
              </figure>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>
<script>
/**
   * Component that represent the application footer.
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 20/12/2019 1:01 a. m.
   */
export default {
  name: 'TheFooter'
}
</script>
<i18n>
  {
  "es": {
  "copyright": "Desarrollado por"
  }
  }
</i18n>
