<template>
  <v-app-bar
    app
    class="b-private-menu"
    color="secondary"
    dark
  >
    <v-toolbar-title>
      <img
        src="./../../../assets/Kontrata@2x.png"
        height="40px"
        class="mt-1"
        @click="goTo('home')"
      >
    </v-toolbar-title>
    <v-spacer />
    <v-menu
      v-model="showAccountMenu"
      :close-on-content-click="showAccountMenu"
      offset-x
    >
      <template v-slot:activator="{ on }">
        <v-btn
          large
          text
          v-on="on"
        >
          <v-avatar
            item
            size="3em"
          >
            <v-icon size="2em">
              fa-user-circle
            </v-icon>
          </v-avatar>
          <span>{{ userInfo.username }}</span>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon size="3em">
                fa-user-circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ userInfo.username }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div
            v-if="this.userInfo.profileCode && this.userInfo.profileCode === 'AD'"
          >
            <v-divider/>

            <v-list-group
              :value="true"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-title>
                  <v-icon size="1em">fa fa-cogs</v-icon>
                  {{  $t('option.settings.title') }}
                </v-list-item-title>
              </template>
              <v-list-item
                v-for="(branch, i) in settingMenuStructure.branches"
                :key="i"
                link
                v-bind:to="branch.toPage"
              >
                <v-list-item-title v-text="branch.title"></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="branch.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>

        <v-divider />

        <v-list>
          <v-list-item
            id="btn-logout"
            link
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon>fa fa-sign-out</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t('option.logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { LOGOUT } from '../../../store/modules/security/mutations-types'
import { mapState } from 'vuex'

/**
   * Component that represent the application private menu bar.
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 20/12/2019 1:01 a. m.
   */

export default {
  name: 'AppPrivateMenu',
  data: () => ({
    showAccountMenu: false,
    settingMenuStructure: {
      'title': 'Configuración',
      'branches': [
        {
          'title': 'Asignación de proyectos a usuarios',
          'icon': 'fa fa-user',
          'toPage': '/project-bind-user'
        }
      ]
    }
  }),
  computed: {
    ...mapState({ userInfo: (state) => state.security.userInfo })
  },
  methods: {
    goTo (nameRoute) {
      this.$router.push({ name: nameRoute })
    },
    /**
       * Application logout.
       *
       * @author C Camilo Castellanos
       * @version 1.0.0
       * @since 20/12/2019 6:11 p. m.
       */
    logout () {
      this.$store.commit(LOGOUT)
      this.$router.go({ name: 'Authentication' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<i18n>
{
  "es": {
    "option": {
      "settings": {
        "title": "Configuración",
        "branches": {
          "projectBindUser": {
            "title": "Asignación de permisos"
          }
        }
      },
      "logout": "Cerrar sesión"
    }
  }
}
</i18n>
