<template>
  <v-app-bar
    class="b-public-menu"
    app
    color="secondary"
    dark
  >
    <v-toolbar-title>
      <img
        src="./../../../assets/Kontrata@2x.png"
        height="40px"
        class="mt-1"
      >
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
/**
 * Component that represent the application menu bar.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 20/12/2019 1:01 a. m.
 */
export default {
  name: 'AppPublicMenu'
}
</script>
