import { render, staticRenderFns } from "./AppPrivateMenu.vue?vue&type=template&id=5b23fc58&scoped=true&"
import script from "./AppPrivateMenu.vue?vue&type=script&lang=js&"
export * from "./AppPrivateMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b23fc58",
  null
  
)

/* custom blocks */
import block0 from "./AppPrivateMenu.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports