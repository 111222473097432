<template>
  <header class="mb-5">
    <app-private-menu v-if="authenticated" />
    <app-public-menu v-else />
  </header>
</template>
<script>
import AppPublicMenu from '../uiComponents/menu/AppPublicMenu'
import AppPrivateMenu from '../uiComponents/menu/AppPrivateMenu'
import { mapState } from 'vuex'

/**
   * Component that represent the application header.
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 20/12/2019 1:01 a. m.
   */
export default {
  name: 'TheHeader',
  components: {
    AppPublicMenu,
    AppPrivateMenu
  },
  data: () => ({
    dialog: false,
    drawer: null
  }),
  computed: {
    ...mapState({ authenticated: state => state.security.authenticated })
  }
}
</script>
